import React, { FunctionComponent, PropsWithChildren } from "react"
import { Card, ListGroup } from "react-bootstrap"

type ListGroupCardProps = {}

const ListGroupCard: FunctionComponent<PropsWithChildren<ListGroupCardProps>> = ({ children }) => {
  /*******************************************************************************************************************
   *
   *  Hooks
   *
   *******************************************************************************************************************/

  /*******************************************************************************************************************
   *
   *  Functions
   *
   *******************************************************************************************************************/

  /*******************************************************************************************************************
   *
   *  Rendering
   *
   *******************************************************************************************************************/

  return (
    <Card>
      <Card.Body>
        <ListGroup>{children}</ListGroup>
      </Card.Body>
    </Card>
  )
}

export default ListGroupCard
