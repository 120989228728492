export const ExaColors = {
  dark: "#4C5B87",
  bright: "#26C1ED",
  orange: "#EF9362",
  red: "#BA2E0B",
  green: "#77A33E",
  purple: "#6C56A3",
  kaki: "#A39C3E",
  blue2: "#468EA3",
  brown: "#A3805F",
  rost: "#A35F74",
  green2: "#46A350",
}
